import Inputmask from 'jquery.inputmask';

// INPUT
var inputFunc, inputFocusIn, inputFocusOut;

inputFunc = function(el){
    var input, label, value;
    input = el;
    label = input.closest('label');
    value = input.val();
    if (value.length >= 1) {
        label.addClass('label--focus');
    } else {
        label.removeClass('label--focus');
    };
};

inputFocusIn = function(){
    var input;
    input = $('.input, .textarea');
    input.on('focus', function(){
        var label;
        label = $(this).closest('label');
        label.addClass('label--focus');
    });
};

inputFocusOut = function() {
    $('.input, .textarea').on('blur', function(){
        var th;
        th = $(this);
        inputFunc(th);
    });
}

inputFocusIn();
inputFocusOut();

$(document).ready(function(){
    // Autocomplete off
    var form = $('form');
    form.attr('autocomplete','off');
    // Masks
    var tel = $('input[type="tel"]');
    tel.inputmask({"mask": "+7 (999) 999-99-99"});
});