import $ from 'jquery';
import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;
window.$this = $(this);

// ANCHOR NAVIGATION
const mobLink = $('.mobile__nav li a')

const mobNav = $('.mobile')
const mobControl = $('.mobile-control')

const mobNavigation = function(el) {
    let testIndex = $('.home').length
    if(testIndex) {
        let elHref = el.attr('href').slice(1)
        let target = $(elHref)
        let targetOff = target.offset().top
        let headerHeight = $('.header').outerHeight()
        if (elHref=='#programs' || elHref=='#smi' || elHref=='#contacts' || elHref=='#reviews') {
            $('html,body').animate({scrollTop: targetOff},'500')
        } else if (elHref=='#about') {
            $('html,body').animate({scrollTop: targetOff - (headerHeight*1.5)},'500')
        } else  {
            $('html,body').animate({scrollTop: targetOff - headerHeight},'500')
        }
        closeNav()
    } else {
        location.href=el.attr('href')
    }
}
const mobLinkClick = function() {
    mobLink.on('click touch', function(e){
        e.preventDefault()
        let el = $(this)
        mobNavigation(el)
    })
}

const openNav = function() {
    if (mobControl.hasClass('open')) {
        mobNav.removeClass('visible')
        mobControl.removeClass('open')
    } else {
        mobNav.addClass('visible')
        mobControl.addClass('open')
    }
}
const closeNav = function() {
    mobNav.removeClass('visible')
    mobControl.removeClass('open')
}
const mobTouch = function() {
    mobControl.on('click touch', function(){
        openNav()
    })
}
$(document).ready(function(){
    mobLinkClick()
    mobTouch()
})
