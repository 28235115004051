import $ from 'jquery';
import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;
window.$this = $(this);

let testIndex = $('.home').length

// ANCHOR NAVIGATION
const upLink = $('.header__logo')
const navLink = $('.header__nav li a')

const navigation = function(el) {
    let elHref = el.attr('href').slice(1)
    let target = $(elHref)
    let targetOff = target.offset().top
    let headerHeight = $('.header').outerHeight()
    if (elHref=='#about') {
        $('html,body').animate({scrollTop: targetOff - headerHeight - 60},'500')
    } else if(elHref=='#director') {
        let choirGalleryHeight = $('.index-director__gallery').outerHeight()
        $('html,body').animate({scrollTop: targetOff - headerHeight + choirGalleryHeight - 180},'500')
    } else {
        $('html,body').animate({scrollTop: targetOff - headerHeight + 10},'500')
    }
}
const navLinkClick = function() {
    navLink.on('click touch', function(e){
        e.preventDefault()
        let el = $(this)
        navigation(el)
    })
}
const upLinkClick = function() {
    upLink.on('click touch', function(e){
        e.preventDefault()
        $('html,body').animate({scrollTop: 0},'500')
    })
}

// BG

const header = $('.header')

const headerClass = function(st) {
    if(st>=24) {
        header.addClass('header--sticky')
    } else {
        header.removeClass('header--sticky')
    }
}

$(window).on('scroll', function(e) {
    $this = $(this)
    let st = $this.scrollTop()
    headerClass(st)
})

// INIT
$(document).ready(function(){
    if(testIndex) {
        upLinkClick()
        navLinkClick()
    }
})
