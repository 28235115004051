import $ from 'jquery';
import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;
window.$this = $(this);

let wh = $(window).height()

const navLink      = $('.header__nav li a, .mobile__nav li a')
const section      = $('.section')
const bannerImage  = $('.index-banner__image')
const bannerText   = $('.index-banner__wrapper')
const directorText = $('.index-director__subline')

$(window).resize(function(){
    let wh = $(window).height()
})

const navScrolling = function(st, wh) {
        section.each(function(){
            let offTop = $(this).offset().top
            let anchor = $(this).attr('id')
            let target = $('a[href="/#'+anchor+'"]')
            if (offTop < st+wh*.5) {
                navLink.removeClass('current')
                target.addClass('current')
            }
        })
}

const parallaxScrolling = function(st, wh) {

    bannerImage.css('transform','translate3d(0,' + (st*0.125) + 'px,0)')
    bannerText.css('transform','translate3d(0,' + (-1*st*0.0625) + 'px,0)')
    let testDirector = $('.index-director__gallery');
    
    if (testDirector.length) {
        let dtFix = directorText.offset().top
        let fix = st + wh + 48
        let ww = $(window).width()
        if (ww>=960 && dtFix <= fix) {
            let newFix = fix - dtFix + 48
            directorText.css('transform','translate3d(0,' + (newFix*-.125) + 'px,0)')
        } else {
            directorText.css('transform','translate3d(0,0,0)')
        }
    }
}

$(window).on('scroll', function(e) {
    let testIndex = $('.home').length
    if (testIndex) {
        $this = $(this)
        let st = $this.scrollTop()
        parallaxScrolling(st, wh)
        navScrolling(st, wh)
    }
})

const videoPlayer  = $('#indexVideo');
const videoControl = $('#indexPlay');
const videoCover   = $('.video__cover');

const videoPlay = function() {
    videoControl.on('click touch', function() {
        $(this).addClass('hidden')
        videoCover.toggle()
        videoPlayer[0].play()
    })
}
$(document).ready(function(){
    videoPlay()
})


